import React, { useState } from "react";
import "./App.css";
// import Start from "./Components/Start";
import Quiz from "./Components/Quiz";
import { Routes, Route } from "react-router-dom"
import Result from "./Components/Result";

import Home from "./Components/Home";
import SignUp from "./Components/SighUp";


function App() {
  const [start, setStart] = useState(false);

  return (
    <div className="quiz">
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<SignUp />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/results" element={<Result />} />
      </Routes>
    </div>
  );
}

export default App;

// var webpack = require('webpack');
// export const URL = "http://192.168.1.117:9091";
export const URL = "https://api.mulyankan.saurashtrarss.org";


// module.exports = {
// plugins: [
//     new webpack.DefinePlugin({
//       'process.env.NODE_ENV': JSON.stringify(process.env.NODE_ENV),
//       'process.env.MY_ENV': JSON.stringify(process.env.MY_ENV),
//     })
// ],
// }
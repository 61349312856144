import styled from "styled-components";
import { Button } from "./Button.jsx";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileIcon from "./Profile.jsx";
// import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import axios from "axios";
import { URL } from '../config.js'



const Title = styled.h1`
  margin-top: 4em;
  font-size: 48px;
`;

const Points = styled.p`
  font-size: 24px;
  margin-bottom: 3em;
`;
// const userName1  = localStorage.getItem('userName')

const today = new Date();
const day = today.getDate();
const month = today.getMonth() + 1
const year = today.getFullYear();


const Result = () => {
  const [userName, setUserName] = useState('')

  const formattedDate = `${day}/${month}/${year}`;
  const location = useLocation();

  const navigate = useNavigate();

  const pts = location.state ? location.state.score : null
  
  if (location.state === null) {
    navigate('/')
  }

  const Quit = () => {
    navigate('/')
  }

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const quizId = localStorage.getItem('quizId');

    const url = URL + '/admin/leaderboard?user_id=' + userId + '&quiz_id=' + quizId

    const data = {
      quiz_id: quizId,
      user_id: userId,
      score: pts
    }

    axios.post(url, data).then((res) => {
      setUserName(res.data.user.name);
      localStorage.setItem('userId', "")
    })
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Title> મુલ્યાંકન પુણૅ </Title>
      <h3 className="error">{pts ? `કાર્યકર્તા નુ નામ : ${userName}` : ""}</h3>
      <h3 className="error">{ pts ? `તારીખ : ${formattedDate}` : ""}</h3>
      <h3 className="error">{pts ? `આપનો સ્કોર : ${pts}` : 'No Score Found, Please Play Quiz First!!!'}</h3>
      <Button style={{ backgroundColor: '#DE651A', color: 'white' }} className="btn" onClick={Quit}>Quit</Button>
    </div>
  );
};

export default Result;

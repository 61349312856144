import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import GameOver from "./Result.jsx";
import { useLocation, useNavigate } from 'react-router-dom';

import { URL } from '../config.js'


const QuizWindow = styled.div`
  text-align: center;
  font-size: clamp(20px, 2.5vw, 24px);
  margin-top: 10vh;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 2em auto;
  @media screen and (min-width: 1180px) {
    width: 50%;
  }
`;

const Option = styled.button`
  display: block;
  border: 1px solid #DE651A;
  border-radius: 15px;
  padding: 15px 30px;
  text-decoration: none;
  color: #DE651A;
  background-color: white;
  transition: 0.3s;
  font-size: 1em;
  outline: none;
  user-select: none;
  margin-top: 1em;
  cursor: pointer;

  @media screen and (min-width: 1180px) {
    &:hover {
      color: white;
      background-color:#DE651A;
    }
  }
`;

const Question = styled.div`
  width: 70%;
  margin: 0 auto;
`;

const Quiz = () => {

  const [quiz, setQuiz] = useState([]);
  const [number, setNumber] = useState(0);
  const [count, setCount] = useState(1)
  const [pts, setPts] = useState(0);
  const [total, setTotal] = useState(-1)

  const location = useLocation();
  const navigate = useNavigate();


  const pickAnswer = (e) => {
    let userAnswer = e.target.outerText;

      if (quiz[number].answer === userAnswer) {
        setPts(pts + 5);
      } else {
        setPts(pts + 0);
      }
    
    setNumber(number + 1);
    setCount(count + 1);
  }

  useEffect(() => {
    if (localStorage.getItem('userId').length == 0) {
      navigate('/');
    } else {
      if (location.state && location.state.id === 'signupCompleted') {

        const url = URL + '/admin/quiz-questions'

        const params = {
          quiz_id: 'bc7ad6c7-2cd6-451f-b8e8-4a2acde3984e',
          from_super_admin: false,
          limit: 100
        }
        const headerConfig = {
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
          },
          params
        }

        axios.get(url, headerConfig).then((res) => {
          localStorage.setItem('quizId', res.data.list[0].quiz.id)
          setTotal(res.data.list.length)
          setQuiz(
            res.data.list.map((item) => ({
              question: item.question,
              options: [item.option_A, item.option_B, item.option_C, item.option_D],
              answer: item.correct_ans === "option_A" ? item.option_A : item.correct_ans === "option_B" ? item.option_B : item.correct_ans === "option_C" ? item.option_C : item.option_D
            }))
          );
        })
          .catch((err) => console.error(err));
      } else {
        navigate('/');
      }
    }
  }, []);

  return (
    <QuizWindow>
      {total !== number && <p style={{ display: 'inline' }}>{count}.</p>}
      {quiz[number] && (
        <>
          <Question style={{ display: 'inline', marginLeft: "1rem" }}
            dangerouslySetInnerHTML={{ __html: quiz[number].question }}
          ></Question>

          <Options>
            {quiz[number].options.map((item, index) => (

              <Option
                key={index}
                dangerouslySetInnerHTML={{ __html: item }}
                onClick={(e) => pickAnswer(e, index)}
              ></Option>
            ))}
          </Options>
        </>
      )}
      {number === total && navigate('/results', { state: { score: pts } })}
    </QuizWindow>
  );
};

export default Quiz;

import * as React from 'react';
import styled, { css } from "styled-components/macro";
import Button from "./Button";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
// import Button from '@mui/material/Button';
import axios from 'axios'
import { URL } from '../config.js'

const Intro = styled.div`
  margin-top: 8em;
  text-align: center;
`;

const btnCSS = css`
  margin-top: 2em;
`;

const Home = () => {

  const navigate = useNavigate();
  
  const StartQuiz = () => {
    navigate('/quiz');
  }


  return (
    <Intro>
      <h1>Welcome To The Quiz.</h1>
      <h4>Learn More In Fun Way With Quiz</h4>
     <Button style={{marginTop:'3rem', backgroundColor:'#DE651A', color:'white'}} onClick={StartQuiz} >
        Start Quiz
      </Button>
    </Intro>
  );
};

export default Home;
